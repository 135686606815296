import React from "react"

import { Layout, SEO, Container } from "../components"
import styles from "./index.module.scss"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 - Page not found" />
    <Container className={styles.container}>
      <div className={styles.intro}>
        <h1>Page not found!</h1>
        <h4>Sorry the page you are looking for is not here</h4>
      </div>
    </Container>
  </Layout>
)

export default NotFoundPage
